@import '~@jobandtalent/design-system/dist/assets/v2/scss/reset';
@import '~@jobandtalent/design-system/dist/styles.css';
@import '~@jobandtalent/backoffice-navigation/dist/styles.css';
@import 'common';

a,
button {
  cursor: pointer;
}

.page__header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-index--header;
}

.ds-atom-drop-down-cell {
  &.with-link {
    padding: 0;

    > a {
      padding: 8px 10px;
      min-width: 110px;
      width: 100%;
      text-align: left;
      display: block;
      white-space: nowrap;
    }
  }
}

textarea[name="data-scope"] {
  white-space: pre;
}

.data-scope-block {
  display: flex;
  gap: 10px;
  padding: 5px 0px;
  input {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    width: 100%;
    height: 35px;
    padding: 0 10px;
    box-shadow: inset 0 1px 0 0 rgba(35, 40, 46, 0.1);
    background-color: #ffffff;
    border: solid 1px rgba(35, 40, 46, 0.1);
    border-radius: 3px;
  }
}