@import 'src/styles/common';

.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: $z-index--menu;
}

.main {
  padding: 60px 0 0 250px;
  min-height: 100vh;
  background-color: $bg_grey;

  &.menuCollapsed {
    padding-left: 60px;
  }
}
